/**
 * @package 	WordPress
 * @subpackage 	Payday Loans
 * @version		1.0.7
 * 
 * Responsive Design CSS Rules
 * Created by CMSMasters
 * 
 */

@import "general.less";

@media only screen {
	/*-------------------------------------------------------------------------*/
	/* Large Monitor
	/*-------------------------------------------------------------------------*/
	@media @monitor_large {
		.cmsmasters_responsive_width {
			width:1440px;
		}
		
		#page {
			&.cmsmasters_boxed {
				width:@cont_width_large + @blocks_hor_pad * 2;
				
				#header, 
				#footer {
					width:@cont_width_large + @blocks_hor_pad * 2;
				}
			}
		}
		
		.post-password-form,
		.header_top_inner, 
		.header_mid_inner, 
		.header_bot_inner, 
		.content_wrap, 
		.headline_inner, 
		.cmsmasters_breadcrumbs, 
		.bottom_outer, 
		.footer_inner, 
		.cmsmasters_row_inner, 
		.cmsmasters_row_title_wrap {
			width:@cont_width_large;
		}
	}
	
	@media @monitor_medium_large {
		.bot_nav > li > a, 
		.mid_nav > li > a {
			padding: 0 10px;
		}
	}
	
	
	
	/*-------------------------------------------------------------------------*/
	/* Medium Monitor
	/*-------------------------------------------------------------------------*/
	@media @monitor_medium {
		.cmsmasters_responsive_width {
			width:1025px;
		}
		
		.fixed_header #header {
			position:fixed;
		}
		
		.header_top_outer, 
		.header_top .top_nav_wrap nav {
			display:block !important;
		}
	}
	
	
	
	/*-------------------------------------------------------------------------*/
	/* Tablet Monitor
	/*-------------------------------------------------------------------------*/
	@media @monitor_tablet {
		.cmsmasters_responsive_width {
			width:1024px;
		}
		
		.gallery {
			&.gallery-columns-9, 
			&.gallery-columns-6 {
				.gallery-item {
					width:round((100% / 3) - (@col_mar * 2), 2);
					
					&:nth-child(6n + 1),
					&:nth-child(9n + 1) {
						clear:none;
					}
					
					&:nth-child(3n + 1) {
						clear:both;
					}
				}
			}
			
			&.gallery-columns-8 {
				.gallery-item {
					width:round((100% / 4) - (@col_mar * 2), 2);
					
					&:nth-child(8n + 1) {
						clear:none;
					}
					
					&:nth-child(4n + 1) {
						clear:both;
					}
				}
			}
			
			&.gallery-columns-7, 
			&.gallery-columns-5, 
			&.gallery-columns-4	{
				.gallery-item {
					width:round((100% / 2) - (@col_mar * 2), 2);
					
					&:nth-child(4n + 1),
					&:nth-child(5n + 1),
					&:nth-child(7n + 1) {
						clear:none;
					}
					
					&:nth-child(2n + 1) {
						clear:both;
					}
				}
			}
		}
		
		#page {
			&.cmsmasters_boxed {
				width: @cont_width_full;
				
				#header, 
				#footer {
					width: @cont_width_full;
				}
			}
			
			&.fixed_header {
				#header {
					position: relative;
				}
				
				#middle, 
				&.enable_header_top #middle, 
				&.enable_header_bottom #middle, 
				&.enable_header_top.enable_header_bottom #middle {
					padding-top: 0;
				}
			}
		}
		
		#header {
			position:relative;
			
			nav {
				height:auto;
				
				div, 
				ul, 
				li, 
				a, 
				span {
					background-image:none !important;
					display:block;
					width:auto;
					height:auto;
					float:none;
					margin:0;
					padding:0;
					visibility:visible;
					position:relative;
					left:auto;
					right:auto;
					top:auto;
					bottom:auto;
					.tal;
					.bd(0);
					.cl;
					.ovh;
					.bdrs(0);
					.op(1);
					.bxsh_none;
				}
				
				div, 
				ul, 
				li, 
				a {
					&:before, 
					&:after {
						content:none;
					}
				}
				
				.menu-item-mega-description-container {
					padding:10px 15px;
				}
				
				li {
					.bdt;
				}
				
				> div > ul > li:first-child {
					.bdt(0);
				}
			}
		}
		
		.post-password-form,
		.header_top_inner, 
		.header_mid_inner, 
		.header_bot_inner, 
		.content_wrap, 
		.headline_inner, 
		.cmsmasters_breadcrumbs, 
		.bottom_outer, 
		.footer_inner, 
		.cmsmasters_row_inner, 
		.cmsmasters_row_title_wrap {
			width: @cont_width_full;
		}
		
		.cmsmasters_column {
			margin-bottom:20px;
			
			.cmsmasters_row_columns_behavior & {
				margin-bottom:0;
			}
			
			&.one_first, 
			&:last-child {
				margin-bottom:0;
			}
		}
		
		.one_fifth, 
		.one_fourth, 
		.three_fourth {
			width:round((100% / 2) - (@col_mar * 2), 2);
			
			.cmsmasters_row_no_margin & {
				width:round((100% / 2), 2);
			}
		}
		
		.cmsmasters_14141414 {
			> .cmsmasters_column:nth-child(2n + 1) {
				clear:left;
			}
		}
		
		.cmsmasters_121414, 
		.cmsmasters_141412 {
			.cmsmasters_column.one_half {
				width:round(100% - (@col_mar * 2), 2);
				float:none;
				clear:both;
				
				.cmsmasters_row_no_margin & {
					width:round(100%, 2);
				}
			}
		}
		
		.cmsmasters_141214 {
			.cmsmasters_column.one_fourth, 
			.cmsmasters_column.one_half {
				width:round((100% / 3) - (@col_mar * 2), 2);
				
				.cmsmasters_row_no_margin & {
					width:round((100% / 3), 2);
				}
			}
		}
		
		.sidebar_layout_14141414 {
			aside {
				width:round((100% / 2) - (@col_mar * 2), 2);
			}
		}
		
		.sidebar_layout_141412 {
			> aside:first-child, 
			> aside:first-child + aside, 
			> div.cl + aside, 
			> div.cl + aside + aside, 
			> div.cmsmasters_widget_divider + aside, 
			> div.cmsmasters_widget_divider + aside + aside {
				width:round((100% / 2) - (@col_mar * 2), 2);
			}
			
			> aside:first-child + aside + aside, 
			> div.cl + aside + aside + aside, 
			> div.cmsmasters_widget_divider + aside + aside + aside {
				width:round(100% - (@col_mar * 2), 2);
				float:none;
				clear:both;
			}
		}
		
		.sidebar_layout_141214 {
			> aside:first-child, 
			> aside:first-child + aside + aside, 
			> div.cl + aside, 
			> div.cl + aside + aside + aside, 
			> div.cmsmasters_widget_divider + aside, 
			> div.cmsmasters_widget_divider + aside + aside + aside {
				width:round((100% / 3) - (@col_mar * 2), 2);
			}
			
			> aside:first-child + aside, 
			> div.cl + aside + aside, 
			> div.cmsmasters_widget_divider + aside + aside {
				width:round((100% / 3) - (@col_mar * 2), 2);
			}
		}
		
		.sidebar_layout_121414 {
			> aside:first-child, 
			> div.cl + aside, 
			> div.cmsmasters_widget_divider + aside {
				width:round(100% - (@col_mar * 2), 2);
				float:none;
				clear:both;
			}
			
			> aside:first-child + aside, 
			> aside:first-child + aside + aside, 
			> div.cl + aside + aside, 
			> div.cl + aside + aside + aside, 
			> div.cmsmasters_widget_divider + aside + aside, 
			> div.cmsmasters_widget_divider + aside + aside + aside {
				width:round((100% / 2) - (@col_mar * 2), 2);
			}
		}
		
		.sidebar_layout_3414 {
			> aside:first-child, 
			> div.cl + aside, 
			> div.cmsmasters_widget_divider + aside {
				width:round((100% / 2) - (@col_mar * 2), 2);
			}
			
			> aside:first-child + aside, 
			> div.cl + aside + aside, 
			> div.cmsmasters_widget_divider + aside + aside {
				width:round((100% / 2) - (@col_mar * 2), 2);
			}
		}
		
		.sidebar_layout_1434 {
			> aside:first-child, 
			> div.cl + aside, 
			> div.cmsmasters_widget_divider + aside {
				width:round((100% / 2) - (@col_mar * 2), 2);
			}
			
			> aside:first-child + aside, 
			> div.cl + aside + aside, 
			> div.cmsmasters_widget_divider + aside + aside {
				width:round((100% / 2) - (@col_mar * 2), 2);
			}
		}
		
		.cmsmasters_stats.stats_mode_circles .cmsmasters_stat_wrap, 
		.cmsmasters_counters .cmsmasters_counter_wrap {
			&.one_fifth, 
			&.one_fourth {
				width:50%;
			}
		}
		
		.cmsmasters_pricing_table {
			&.pricing_four {
				.cmsmasters_pricing_item {
					width:50%;
				}
			}
		}
		
		.header_top {
			.ovh;
			.cl;
			
			.header_top_but {
				display:block;
			}
			
			.header_top_outer {
				height:auto;
				.dn;
				.ovh;
			}
			
			.header_top_inner {
				height:auto;
				padding:0;
				.ovh;
			}
			
			.meta_wrap > *, 
			.meta_wrap > *:last-child {
				margin:0 20px 5px;
			}
			
			.header_top_right {
				float:none;
			}
			
			.social_wrap {
				padding:15px 0;
			}
			
			.social_wrap .social_wrap_inner {
				.tac;
			}
			
			.top_nav_wrap nav {
				.dn;
			}
			
			.responsive_top_nav {
				display:block;
			}
			
			.header_top_left {
				padding:35px 15px 10px;
				
				&:after {
					content:none;
				}
			}
			
			.meta_wrap {
				display:block;
				.tac;
			}
		}
		
		.header_mid {
			.search_wrap:not(.search_opened) {
				.search_bar_wrap {
					width:40px;
					height:40px;
					margin:0;
					
					.search_button {
						width:40px;
						height:40px;
					}
				}
			}
		}
		
		#header .top_line_nav {
			padding:20px 0;
			
			.nav_item_wrap {
				padding:5px 0;
				.tac;
			}
		}
		
		#header .header_mid {
			.cl;
			
			.header_mid_inner {
				padding:0 30px;
			}
			
			.search_wrap {
				.bd(0);
			}
			
			.resp_mid_nav_wrap {
				display:block;
				
				.resp_mid_nav {
					font-size:20px;
					line-height:20px;
					width:40px;
					height:40px;
				}
			}
			
			.mid_nav_wrap {
				display:block;
				height:auto;
				float:none;
				margin:0 -@blocks_hor_pad;
				visibility:visible;
				.ovh;
				.cl;
				.op(1);
				
				nav {
					.dn;
				}
			}
		}
		
		#header .header_bot {
			.ovh;
			.cl;
			
			.header_bot_outer {
				.ovh;
			}
			
			.header_bot_inner {
				padding:0;
			}
			
			.resp_bot_nav_wrap {
				display:block;
				
				.resp_bot_nav_outer {
					.tac;
				}
				
				.resp_bot_nav {
					font-size:30px;
				}
			}
			
			.bot_nav_wrap {
				display:block;
				height:auto;
				float:none;
				margin:0;
				visibility:visible;
				.ovh;
				.cl;
				.op(1);
				
				nav {
					.dn;
				}
			}
		}
		
		#header .navigation {
			padding:20px 10px;
			
			a {
				padding:8px 0 8px 10px;
			}
			
			li li {
				> a {
					padding-left:30px;
					padding-right:0;
				}
				
				li {
					> a {
						padding-left:50px;
						padding-right:0;
					}
				}
			}
			
			.nav_item_wrap {
				display:inline-block;
				vertical-align:middle;
			}
			
			.nav_title {
				display:inline-block;
				vertical-align:bottom;
			}
			
			.nav_tag {
				display:inline-block;
				vertical-align:middle;
				padding:0 5px;
				margin:0 0 0 10px;
			}
			
			.nav_subtitle {
				display:block;
			}
			
			.cmsmasters_resp_nav_toggle {
				width:18px;
				height:18px;
				font-size:18px;
				line-height:18px;
				position:absolute;
				left:auto;
				right:11px;
				top:50%;
				margin-top:-9px;
			}
		}
		
		.cmsmasters_items_filter_wrap .cmsmasters_items_filter_block {
			width:100%;
			margin-top:10px;
			.tac;
			
			li {
				margin:0 2px 4px 2px;
			}
		}
		
		.cmsmasters_items_filter_wrap .cmsmasters_items_sort_block {
			float:none;
			.tac;
			
			> a {
				display:inline-block;
				float:none;
				margin:0;
			}
		}
		
		.cmsmasters_items_filter_wrap .cmsmasters_items_filter_list {
			padding:0;
		}
		
		.cmsmasters_quotes_grid .cmsmasters_quote {
			.quote_four& {
				width:(50% - @col_mar);
			}
		}
	}
	
	
	
	/*-------------------------------------------------------------------------*/
	/*	Tablet Monitor 950
	/*-------------------------------------------------------------------------*/
	@media @monitor_tablet_950 {
		.cmsmasters_responsive_width {
			width:950px;
		}
		
		.content_wrap {
			&.l_sidebar, 
			&.r_sidebar {
				.cmsmasters_row {
					padding-left:0;
					padding-right:0;
					margin-left:0;
					margin-right:0;
				}
			}
		}
		
		#page .content, 
		#page .sidebar {
			width: @cont_width_full;
			padding:25px 0 0;
		}
		
		.cmsmasters_open_project {
			padding:0;
			
			.project_content {
				padding:25px 0 0;
				
				&.with_sidebar {
					width:100%;
					float:none;
				}
			}
			
			.project_sidebar {
				width:100%;
				float:none;
				padding:25px 0 0;
			}
		}
		
		.cmsmasters_open_profile {
			padding:0;
			
			.profile_content {
				&.with_sidebar {
					width:100%;
					float:none;
				}
			}
			
			.profile_sidebar {
				width:100%;
				float:none;
				padding:25px 0 0;
			}
		}
		
		.quote_three.cmsmasters_quotes_grid {
			.cmsmasters_quote {
				width:(50% - @col_mar);
			}
		}
	}
	
	
	
	/*-------------------------------------------------------------------------*/
	/* Small Tablet Monitor
	/*-------------------------------------------------------------------------*/
	@media @monitor_tablet_small {
		.cmsmasters_responsive_width {
			width:767px;
		}
		
		.gallery {
			&.gallery-columns-8	{
				.gallery-item {
					width:round((100% / 2) - (@col_mar * 2), 2);
					
					&:nth-child(2n + 1) {
						clear:both;
					}
				}
			}
		}
	
		.one_first, 
		.one_half, 
		.one_third, 
		.two_third, 
		.one_fourth, 
		.one_fifth, 
		.three_fourth, 
		.cmsmasters_121414 .cmsmasters_column.one_half, 
		.cmsmasters_141412 .cmsmasters_column.one_half, 
		.cmsmasters_141214 .cmsmasters_column.one_fourth, 
		.cmsmasters_141214 .cmsmasters_column.one_half {
			width:round(100% - (@col_mar * 2), 2);
			float:none;
			clear:both;
			
			.cmsmasters_row_no_margin & {
				width:round(100%, 2);
			}
			
			.cmsmasters_row_columns_behavior & {
				display:block;
			}
		}
	
		.cmsmasters_row_margin.cmsmasters_row_columns_behavior {
			display:block;
			
			.cmsmasters_featured_block .featured_block_text:empty {
				padding-bottom:100%;
			}
		}
		
		.cmsmasters_row_no_margin {
			.cmsmasters_row_margin {
				display:block;
			}
		}
		
		.sidebar_layout_14141414, 
		.sidebar_layout_141412, 
		.sidebar_layout_141214, 
		.sidebar_layout_121414, 
		.sidebar_layout_131313, 
		.sidebar_layout_3414, 
		.sidebar_layout_1434, 
		.sidebar_layout_2313, 
		.sidebar_layout_1323, 
		.sidebar_layout_1212 {
			aside {
				width:round(100% - (@col_mar * 2), 2) !important;
			}
		}
		
		.cmsmasters_featured_block .featured_block_inner {
			width:100% !important;
		}
		
		.cmsmasters_stats.stats_mode_circles .cmsmasters_stat_wrap, 
		.cmsmasters_counters .cmsmasters_counter_wrap {
			&.one_third {
				width:50%;
			}
		}
		
		.cmsmasters_pricing_table {
			&.pricing_three {
				.cmsmasters_pricing_item {
					width:100%;
					float:none;
					.cl;
				}
			}
		}
		
		.cmsmasters_clients_grid {
			&.clients_five, 
			&.clients_four, 
			&.clients_three, 
			&.clients_two {
				.cmsmasters_clients_item {
					width:100%;
					float:none;
					.cl;
				}
			}
		}
		
		#header .header_mid {
			.header_mid_inner {
				padding-top:15px;
			}
			
			.logo_wrap {
				width:auto;
				float:none;
				margin:0 auto 10px;
				.tac;
				
				&:after {
					content:none;
				}
				
				.logo {
					img {
						position:static;
					}
				}
			}
			
			.search_resp_nav_wrap {
				width:auto;
				float:none;
				.tac;
			}
			
			.search_wrap {
				width:auto;
				float:none;
				margin:0 5px 30px;
				padding:0;
				display:inline-block;
				vertical-align:middle;
				
				.search_wrap_inner {
					.tac;
				}
			}
			
			.resp_mid_nav_wrap {
				float:none;
				margin:0 5px 30px;
				display:inline-block;
				vertical-align:middle;
				
				.resp_mid_nav_outer {
					.tac;
					
					&:after {
						content:none;
					}
				}
				
				.search_bar_wrap {
					margin:0;
				}
			}
			
			.social_wrap {
				margin:0px 0 30px;
				
				.social_wrap_inner {
					.tac;
				}
			}
			
			.slogan_wrap {
				margin:-20px 0 40px;
				
				.slogan_wrap_inner {
					.tac;
					
					&:after {
						content:none;
					}
				}
				
				.slogan_wrap_text {
					.tac;
				}
			}
			
			.mid_nav_wrap {
				height:auto;
				float:none;
				margin:0 -@blocks_hor_pad;
				visibility:visible;
				.ovh;
				.cl;
				.op(1);
				
				nav {
					.dn;
				}
			}
		}
		
		#header .header_bot {
			.resp_bot_nav_wrap {
				padding:15px 0;
			}
		}
		
		.cmsmasters_footer_default {
			.footer_nav {
				display:block;
				.tac;
				
				> li {
					display:inline-block;
					float:none;
				}
			}
		}
		
		.cmsmasters_footer_small {
			.footer_nav_wrap {
				padding:0 0 10px;
				float:none;
				
				nav > div {
					.tac;
					
					&:after {
						content:none;
					}
				}
			}
			
			.footer_nav {
				display:block;
				.tac;
				
				> li {
					display:inline-block;
					float:none;
				}
			}
			
			.social_wrap {
				padding:0 0 10px;
				float:none;
				
				.social_wrap_inner {
					.tac;
					
					&:after {
						content:none;
					}
				}
			}
			
			.footer_custom_html_wrap {
				padding:0 0 10px;
				float:none;
				
				.footer_custom_html {
					.tac;
				}
			}
			
			.footer_copyright {
				padding:0;
				.tac;
			}
		}
		
		.blog.timeline {
			height:auto !important;
			
			&:before {
				content:none;
			}
			
			.cmsmasters_timeline_left, 
			.cmsmasters_timeline_right {
				.cmsmasters_post_info {
					position:relative;
					left:0;
					
					.cmsmasters_post_date:before {
						content:'';
					}
				}
			}
			
			.post {
				width:100%;
				padding-top:40px;
				position:relative !important;
				left:auto !important;
				right:auto !important;
				top:auto !important;
				bottom:auto !important;
				-webkit-transform:none !important;
				-moz-transform:none !important;
				-ms-transform:none !important;
				-o-transform:none !important;
				transform:none !important;
				
				&:first-child {
					padding-top:0;
				}
				
				&:before {
					content:none;
				}
				
				&.cmsmasters_timeline_left, 
				&.cmsmasters_timeline_right {
					.cmsmasters_timeline_margin {
						margin-left:0;
						margin-right:0;
					}
				}
			}
		}
		
		.cmsmasters_quotes_grid {
			&.quote_one, 
			&.quote_four, 
			&.quote_three, 
			&.quote_two {
				.cmsmasters_quote {
					width:(100% - @col_mar);
					
					.cmsmasters_quote_info_wrap {
						padding:0;
						display:block;
						.tac;
					}
					
					.cmsmasters_quote_image {
						padding:0 0 20px;
						display:block;
						.tac;
					}
					
					.cmsmasters_quote_subtitle_wrap {
						.tac;
					}
					
					.cmsmasters_quote_content {
						padding:50px 0 30px 0;
						.tac;
						
						&:before {
							left:50%;
							margin-left:-15px;
						}
					}
				}
			}
		}
		
		.cmsmasters_tabs.tabs_mode_tab .cmsmasters_tabs_list {
			display:block;
			
			> li {
				display:block;
				margin-bottom:20px;
				width:100%;
			}
		}
		
		.widget_custom_posts_tabs_entries .cmsmasters_tabs.tabs_mode_tab .cmsmasters_tabs_list_item {
			width:33%;
			.fl;
		}
		
		.cmsmasters_tabs.tabs_mode_tab .cmsmasters_tab_inner {
			padding:45px 0 0;
		}
		
		.cmsmasters_tabs.tabs_mode_tour .cmsmasters_tabs_list {
			width:40%;
		}
		
		.cmsmasters_tabs.tabs_mode_tour .cmsmasters_tabs_wrap {
			width:60%;
		}
		
		.cmsmasters_tabs.tabs_mode_tour .cmsmasters_tab_inner {
			padding:0 0 0 30px;
		}
		
		.cmsmasters_profile_vertical .cmsmasters_img_wrap {
			width:33%;
		}
		
		.cmsmasters_profile_vertical .cmsmasters_img_wrap + .profile_inner {
			width:67%;
			padding:0 0 0 20px;
		}
		
		.cmsmasters_archive_type .cmsmasters_archive_item_img_wrap {
			width:33%;
			margin:0 20px 0 0;
		}
		
		#page .cmsmasters_sitemap_wrap .cmsmasters_sitemap_category li, 
		#page .cmsmasters_sitemap_wrap .cmsmasters_sitemap_archive li, 
		#page .cmsmasters_sitemap_wrap .cmsmasters_sitemap li {
			float:none;
			width:auto;
			display:block;
			padding:0;
		}
		
		#page .headline_outer {
			.headline_inner {
				padding-top:20px;
				padding-bottom:20px;
				
				.headline_aligner {
					.dn;
				}
			}
		}
		
		#page .cmsmasters_breadcrumbs {
			.cmsmasters_breadcrumbs_aligner {
				.dn;
			}
			
			.cmsmasters_breadcrumbs_inner {
				padding-bottom:0;
			}
		}
	}
	
	
	
	/*-------------------------------------------------------------------------*/
	/*	Tablet Monitor 600
	/*-------------------------------------------------------------------------*/
	@media @monitor_tablet_600 {
		.cmsmasters_responsive_width {
			width:600px;
		}
		
		html.cmsmasters_html {
			margin-top:0 !important;
			
			body.admin-bar {
				padding-top:46px;
			}
		}	
	}
	
	
	
	/*-------------------------------------------------------------------------*/
	/* Phone Monitor
	/*-------------------------------------------------------------------------*/
	@media @monitor_phone {
		.cmsmasters_responsive_width {
			width:540px;
		}
		
		#page .gallery .gallery-item {
			width:round(100% - (@col_mar * 2), 2);
			float:none;
			clear:both;
		}
	
		.cmsmasters_stats.stats_mode_circles .cmsmasters_stat_wrap, 
		.cmsmasters_counters .cmsmasters_counter_wrap {
			&.one_fifth, 
			&.one_fourth, 
			&.one_third, 
			&.one_half {
				width:100%;
			}
		}
		
		.cmsmasters_pricing_table {
			&.pricing_four, 
			&.pricing_three, 
			&.pricing_two, 
			&.pricing_one {
				.cmsmasters_pricing_item {
					width:100%;
					float:none;
				}
			}
		}
		
		.cmsmasters_comment_item {
			.cmsmasters_comment_item_avatar {
				margin-bottom:10px;
			}
		}
		
		#page .headline_outer {
			.headline_inner {
				.headline_text {
					display:block;
					.tac;
				}
			}
		}
		
		#page .cmsmasters_breadcrumbs {
			float:none;
			.tac;
		}
		
		#page .cmsmasters_profile_vertical .cmsmasters_img_wrap {
			width:100%;
			
			img {
				width:100%;
			}
		}
		
		#page .cmsmasters_profile_vertical .cmsmasters_img_wrap + .profile_inner {
			width:100%;
			padding:10px 0 0;
		}
		
		.cmsmasters_archive_type .cmsmasters_archive_item_img_wrap {
			width:100%;
			float:none;
			margin:0 0 20px;
		}
		
		.cmsmasters_tabs.tabs_mode_tour {
			display:block;
		}
		
		.cmsmasters_tabs.tabs_mode_tour .cmsmasters_tabs_list {
			width:100%;
			display:block;
			
			li:before {
				content:none;
				display:none;
			}
		}
		
		.cmsmasters_tabs.tabs_mode_tour .cmsmasters_tabs_wrap {
			width:100%;
			display:block;
		}
		
		#page .cmsmasters_tabs.tabs_mode_tour .cmsmasters_tab_inner {
			padding:20px 0 0;
		}
		
		.cmsmasters_twitter_wrap {
			.owl-buttons {
				left:0;
				right:0;
				top:0;
				bottom:auto;
			}
			
			.cmsmasters_twitter_item {
				padding:60px 0 50px;
			}
			
			.published {
				display:block;
				.tac;
			}
			
			.cmsmasters_twitter_item_content {
				.tac;
			}
		}
		
		.cmsmasters_open_post .cmsmasters_open_post_info,  
		.cmsmasters_post_default .cmsmasters_post_info {
			width:auto;
			float:none;
			
			.cmsmasters_post_date {
				width:auto;
				.tac;
			}
			
			.cmsmasters_open_post_info_bot, 
			.cmsmasters_post_info_bot {
				.tac;
				
				> span {
					float:none;
					width:auto;
					padding:0 10px;
				}
			}
			
			.cmsmasters_post_cont_inner_wrap {
				margin-left:0;
			}
		}
		
		.cmsmasters_open_post .cmsmasters_open_post_inner {
			margin:35px 0 0 0;
		}
		
		.cmsmasters_post_default .cmsmasters_post_cont_inner_wrap {
			margin:35px 0 0 0;
		}
		
		#page .about_author {
			.about_author_avatar {
				float:none;
				width:90px;
				margin:0 0 10px;
				display:inline-block;
			}
			
			.about_author_cont, 
			.about_author_inner {
				.tac;
			}
		}
	}
	
	
	
	/*-------------------------------------------------------------------------*/
	/* Small Phone Monitor
	/*-------------------------------------------------------------------------*/
	@media @monitor_phone_small {
		.cmsmasters_responsive_width {
			width:320px;
		}
		
		.post-password-form,
		.header_top_inner, 
		.header_mid_inner, 
		.header_bot_inner, 
		.content_wrap, 
		.headline_inner, 
		.cmsmasters_breadcrumbs, 
		.bottom_outer, 
		.footer_inner, 
		.cmsmasters_row_inner, 
		.cmsmasters_row_title_wrap {
			width: @cont_width_min;
		}
	}
}

